const API_URL = "api/";

//APIの設定データ
const API = {
  // authorization
  admin_authorization: {
    name: `admin/auth/`,
    method: "POST",
    response: "json"
  },
  authorization: { name: `auth/`, method: "POST", response: "json" },
  refresh: { name: "refresh", method: "POST", response: "json" },
  // user
  get_login_user: { name: "users/", method: "GET", response: "json" },
  get_user: { name: "users/", method: "GET", response: "json" },
  get_user_list: { name: "users/all", method: "GET", response: "json" },
  add_user: { name: "users/", method: "POST", response: "bool" },
  set_user: { name: "users/", method: "PUT", response: "bool" },
  delete_user: { name: "users/", method: "DELETE", response: "bool" },
  // devices
  get_device_list: { name: "device/", method: "GET", response: "json" },
  get_device: { name: "device/", method: "GET", response: "json" },
  add_device: { name: "device/", method: "POST", response: "json" },
  set_device: { name: "device/", method: "PUT", response: "bool" },
  delete_device: { name: "device/", method: "DELETE", response: "bool" },
  // groups
  get_group: { name: "group/", method: "GET", response: "json" },
  get_joined_group: { name: "group/joined/", method: "GET", response: "json" },
  add_group: { name: "group/", method: "POST", response: "json" },
  set_group: { name: "group/", method: "PUT", response: "bool" },
  delete_group: { name: "group/", method: "DELETE", response: "bool" },
  // alert
  get_alert_list: { name: "alert/", method: "GET", response: "json" },
  add_alert: { name: "alert/", method: "POST", response: "json" },
  set_alert: { name: "alert/", method: "PUT", response: "bool" },
  delete_alert: { name: "alert/", method: "DELETE", response: "bool" },
  // mail
  get_mail_list: { name: "mail/", method: "GET", response: "json" },
  // organization
  get_organization: { name: "organization/", method: "GET", response: "json" },
  add_organization: { name: "organization/", method: "POST", response: "json" },
  set_organization: { name: "organization/", method: "PUT", response: "bool" },
  delete_organization: {
    name: "organization/",
    method: "DELETE",
    response: "bool"
  },
  add_organization_user: {
    name: "organization/",
    method: "POST",
    response: "json"
  },
  get_organization_group: {
    name: "organization/",
    method: "GET",
    response: "json"
  },
  add_organization_group: {
    name: "organization/",
    method: "POST",
    response: "json"
  },
  // group device
  get_group_device: { name: "group/", method: "GET", response: "json" },
  get_group_device_data: { name: "group/", method: "GET", response: "json" },
  get_group_device_blob: { name: "group/", method: "GET", response: "blob" },
  // device data
  get_device_data: { name: "store/", method: "GET", response: "json" },
  get_blob: { name: "store/", method: "GET", response: "blob" },
  delete_data: { name: "store/", method: "DELETE", response: "json" }
};

//======================================
//APIを呼び出す（fetchDataを呼び出す）
//======================================
async function callAPI(apiType = "", request = {}, id = "", token = "") {
  let url = API_URL + API[apiType].name + id;
  let responseType = API[apiType].response;

  //API通信を行う
  let response = null;
  if (API[apiType].method == "GET") {
    response = await fetch(url, {
      method: API[apiType].method,
      mode: "cors", // no-cors, cors, *same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, same-origin, *omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    });
  } else if (API[apiType].method == "IMAGE") {
    response = await fetch(url, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, same-origin, *omit
      headers: {
        "Content-Type": "image/tiff",
        Authorization: "Bearer " + token
      },
      body: request
    });
  } else {
    response = await fetch(url, {
      method: API[apiType].method,
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, same-origin, *omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(request)
    });
  }

  //レスポンスを加工
  // console.log('fetch status:', response.status)
  if (responseType == "json") {
    if (response.status == 200 || response.status == 201) {
      return response.json();
    } else {
      return {};
    }
  } else if (responseType == "bool") {
    if (response.status == 200 || response.status == 201) {
      return true;
    } else {
      return false;
    }
  } else if (responseType == "blob") {
    if (response.status == 200 || response.status == 201) {
      return response.blob();
    } else {
      return null;
    }
  }
}

//======================================
//authorization
//======================================
export async function admin_authorization(user_id, password) {
  return await callAPI(
    "admin_authorization",
    {
      user_id: user_id,
      password: password
    },
    "",
    ""
  );
}

export async function authorization(user_id, password) {
  console.log("@authorization");
  return await callAPI(
    "authorization",
    {
      user_id: user_id,
      password: password
    },
    "",
    ""
  );
}

export async function refresh(refreash_token) {
  console.log("@refresh");
  return await callAPI("refresh", {}, "", refreash_token);
}

//======================================
//users
//======================================
export async function get_login_user(token) {
  console.log("@get_login_user");
  return await callAPI("get_login_user", {}, "", token);
}

export async function get_user(token, user_id = "") {
  console.log("@get_user");
  return await callAPI("get_user", {}, user_id, token);
}

export async function get_user_list(token) {
  console.log("@get_user_list");
  return await callAPI("get_user_list", {}, "", token);
}

export async function add_user(token, user_id, name, password, email, role) {
  console.log("@add_user");
  return await callAPI(
    "add_user",
    {
      user_id: user_id,
      name: name,
      password: password,
      email: email,
      role: role
    },
    "",
    token
  );
}

export async function set_user(token, user_id, name, password, email, role) {
  console.log("@set_user");
  return await callAPI(
    "set_user",
    {
      name: name,
      password: password,
      email: email,
      role: role
    },
    user_id,
    token
  );
}

export async function delete_user(token, user_id) {
  console.log("@delete_user");
  return await callAPI("delete_user", {}, user_id, token);
}

//======================================
//device
//======================================
export async function get_device_list(token) {
  console.log("@get_device_list");
  return await callAPI("get_device_list", {}, "", token);
}

export async function get_device(token, device_id = "") {
  console.log("@get_device");
  return await callAPI("get_device", {}, device_id, token);
}

export async function add_device(
  token,
  device_id,
  name,
  description,
  date,
  regular_time,
  position,
  endpoint_email
) {
  console.log("@add_device");
  return await callAPI(
    "add_device",
    {
      device_id: device_id,
      name: name,
      description: description,
      date: date,
      regular_time: regular_time,
      position: position,
      endpoint_email: endpoint_email
    },
    "",
    token
  );
}

export async function set_device(
  token,
  devive_id,
  name,
  description,
  date,
  regular_time,
  position,
  endpoint_email
) {
  console.log("@set_device");
  return await callAPI(
    "set_device",
    {
      name: name,
      description: description,
      date: date,
      regular_time: regular_time,
      position: position,
      endpoint_email: endpoint_email
    },
    devive_id,
    token
  );
}

export async function delete_device(token, devive_id) {
  console.log("@delete_device");
  return await callAPI("delete_device", {}, devive_id, token);
}

//======================================
//groups
//======================================
export async function get_group_list(token) {
  console.log("@get_group_list");
  return await callAPI("get_group", {}, "", token);
}

export async function get_group(token, group_id = "") {
  console.log("@get_group");
  return await callAPI("get_group", {}, group_id, token);
}

export async function get_joined_group(token) {
  console.log("@get_joined_group");
  return await callAPI("get_joined_group", {}, "", token);
}

export async function add_group(token, name, address, organization_id) {
  console.log("@add_group");
  return await callAPI(
    "add_group",
    {
      name: name,
      address: address,
      organization_id: organization_id
    },
    "",
    token
  );
}

export async function set_group(token, group_id, name, address, role) {
  console.log("@set_group");
  return await callAPI(
    "set_group",
    {
      name: name,
      address: address,
      role: role
    },
    group_id,
    token
  );
}

export async function delete_group(token, group_id) {
  console.log("@delete_group");
  return await callAPI("delete_group", {}, group_id, token);
}

//======================================
//alert
//======================================
export async function get_alert_list(token, group_id) {
  console.log("@get_alert_list");
  return await callAPI("get_alert_list", {}, group_id, token);
}

export async function add_alert(
  token,
  group_id,
  name,
  description,
  device_id,
  users
) {
  console.log("@add_alert");
  return await callAPI(
    "add_alert",
    {
      name: name,
      description: description,
      device_id: device_id,
      users: users
    },
    group_id,
    token
  );
}

export async function set_alert(
  token,
  group_id,
  alert_id,
  name,
  description,
  device_id,
  users
) {
  console.log("@set_alert");
  return await callAPI(
    "set_alert",
    {
      name: name,
      description: description,
      device_id: device_id,
      users: users
    },
    `${group_id}/${alert_id}`,
    token
  );
}

export async function delete_alert(token, group_id, alert_id) {
  console.log("@delete_alert");
  return await callAPI("delete_alert", {}, `${group_id}/${alert_id}`, token);
}

//======================================
//mail
//======================================
export async function get_mail_list(token, group_id) {
  console.log("@get_mail_list");
  return await callAPI("get_mail_list", {}, group_id, token);
}

//======================================
//organizations
//======================================
export async function get_organization(token) {
  console.log("@get_organization");
  return await callAPI("get_organization", {}, "", token);
}

export async function add_organization(
  token,
  name,
  description,
  users,
  devices
) {
  console.log("@add_organization");
  return await callAPI(
    "add_organization",
    {
      name: name,
      description: description,
      users: users,
      devices: devices
    },
    "",
    token
  );
}

export async function set_organization(
  token,
  organization_id,
  name,
  description,
  users,
  devices
) {
  console.log("@set_organization");
  return await callAPI(
    "set_organization",
    {
      name: name,
      description: description,
      users: users,
      devices: devices
    },
    organization_id,
    token
  );
}

export async function delete_organization(token, organization_id) {
  console.log("@delete_organization");
  return await callAPI("delete_organization", {}, organization_id, token);
}

export async function add_organization_user(
  token,
  organization_id,
  user_id,
  name,
  password,
  email
) {
  console.log("@add_organization_user");
  return await callAPI(
    "add_organization_user",
    {
      user_id: user_id,
      name: name,
      password: password,
      email: email
    },
    `${organization_id}/user/`,
    token
  );
}

export async function get_organization_group(token, organization_id) {
  console.log("@get_organization_group");
  return await callAPI(
    "get_organization_group",
    {},
    `${organization_id}/group/`,
    token
  );
}

export async function add_organization_group(
  token,
  organization_id,
  name,
  address
) {
  console.log("@add_organization_group");
  return await callAPI(
    "add_organization_group",
    {
      name: name,
      address: address
    },
    `${organization_id}/group/`,
    token
  );
}

//======================================
//group device
//======================================

export async function get_group_device(token, group_id) {
  console.log("@get_group_device");
  return await callAPI("get_group_device", {}, `${group_id}/device/`, token);
}

export async function get_group_device_data(
  token,
  group_id,
  device_id,
  limit = 1000,
  startAfter = null,
  endBefore = null
) {
  let queryParams = `?limit=${limit}`;
  if (startAfter !== null) {
    queryParams += `&startAfter=${startAfter}`;
  }
  if (endBefore !== null) {
    queryParams += `&endBefore=${endBefore}`;
  }

  return await callAPI(
    "get_group_device_data",
    {},
    `${group_id}/device/${device_id}${queryParams}`,
    token
  );
}

export async function get_group_device_blob(
  token,
  group_id,
  device_id,
  src_id
) {
  console.log("@get_group_device_blob");
  return await callAPI(
    "get_group_device_blob",
    {},
    `${group_id}/blob/${device_id}/${src_id}`,
    token
  );
}

//======================================
//device data
//======================================

export async function get_device_data(
  token,
  device_id,
  limit = 1000,
  startAfter = null,
  endBefore = null
) {
  console.log("@get_device_data");
  let queryParams = `?limit=${limit}`;
  if (startAfter !== null) {
    queryParams += `&startAfter=${startAfter}`;
  }
  if (endBefore !== null) {
    queryParams += `&endBefore=${endBefore}`;
  }

  return await callAPI(
    "get_device_data",
    {},
    `${device_id}${queryParams}`,
    token
  );
}

export async function get_blob(token, src_id) {
  console.log("@get_blob");
  return await callAPI("get_blob", {}, `blob/${src_id}`, token);
}

export async function delete_data(token, src_id) {
  console.log("@delete_data");
  return await callAPI("delete_data", {}, src_id, token);
}
